import dayjs from 'dayjs'

export const TRANSACTION_PENDING_LIMIT_HOURS = 72

export const getIsTransactionPending = (date: string) => {
  const pendingLimitDate = dayjs(date).add(
    TRANSACTION_PENDING_LIMIT_HOURS,
    'hour'
  )

  return pendingLimitDate.isAfter(dayjs())
}
