import dayjs from 'dayjs'
import { getIsTransactionPending } from 'modules/bank/lib/calculation/date'
import { Tag } from 'modules/common/components/_ui/Tag/Tag'
import { useTranslation } from 'modules/common/hooks/useTranslation'

export const TransactionListDateDetails = ({ date }: { date: string }) => {
  const { t } = useTranslation(['bank-components', 'common'])

  return getIsTransactionPending(date) ? (
    <td className="w-1/4 min-w-[88px] py-3 font-bold text-info-400">
      <div className="flex items-center gap-2">
        <span>{dayjs(date).format('DD/MM/YYYY')}</span>
        <div className="flex items-center">
          <Tag
            variant="plain"
            color="warning"
            className="w-24 min-w-min leading-5"
          >
            {t('transactionList.pending')}
          </Tag>
        </div>
      </div>
    </td>
  ) : (
    <td className="w-1/4 min-w-[88px] py-3 font-bold text-info-400">
      {dayjs(date).format('DD/MM/YYYY')}
    </td>
  )
}
